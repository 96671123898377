import React from 'react';

const Expand = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M5 12.069a.8.8 0 001.366.565l4.068-4.068a.8.8 0 000-1.132L6.366 3.366A.8.8 0 005 3.93v8.138z"
         fill="currentColor"
      />
   </svg>
);

export default Expand;