import React from 'react';

const Clock = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M15.5 8a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z"
         stroke="#555"
         strokeMiterlimit={10}
         strokeLinecap="round"
         strokeLinejoin="round"
      />
      <path
         d="M7.75 4v4l3 2"
         stroke="#555"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
   </svg>
);

export default Clock;