import { Col, Row } from 'antd';
import React, { memo } from 'react';
import ClaireAlapetile from "../../assets/images/claire_alapetile.svg";
import JeanMichon from "../../assets/images/jean_michon.svg";
import KathyPrichardJones from "../../assets/images/kathy_prichard_jones.svg";
import SallyBlair from "../../assets/images/sally_blair.svg";
import RodriguezGalindo from "../../assets/images/rodriguez_galindo.svg";
import IbrahimQaddoumi from "../../assets/images/ibrahim_qaddoumi.svg";
import HirotoInaba from "../../assets/images/hiroto_inaba.svg";
import PierreMary from "../../assets/images/pierre_mary.png";
import NaylaNicolas from "../../assets/images/nayla_nicolas.svg";
import YiHeongGyu from "../../assets/images/yi_heong_gyu.svg";
import PhanTruc from "../../assets/images/phan_truc.svg";
import JuliaPalma from "../../assets/images/julia_palma.svg";
import MichelPeuchmaur from "../../assets/images/michel_peuchmaur.svg";
import TranThiHongHa from "../../assets/images/tran_thi_hong_ha.svg";
import MichelleHermiston from "../../assets/images/michelle_hermiston.svg";
import SelinaLuger from "../../assets/images/selina_luger.svg";
import ChetanDhamneTata from "../../assets/images/chetan_dhamne_tata.svg";
import ThuanChong from "../../assets/images/thuan_chong.svg";
import InfoItem from './InfoItem';
import useTranslation from '../../translation/components/useTranslation';
import DoHuyenNga from "../../assets/images/do_huyen_nga.png";
import DoDungKien from "../../assets/images/do_dung_kien.png";
import KiDongPark from "../../assets/images/ki_dong_park.png";
import TcQuah from "../../assets/images/tc_quah.png";
import TranTrungToan from "../../assets/images/tran_trung_toan.png";
import VuDinhQuang from "../../assets/images/vu_dinh_quang.png";
import PascalePhilippeChomette from "../../assets/images/pascale_philippe_chomette.png";
import VietHuong from "../../assets/images/viet_huong.png";

const speakerList = [
   {
      img: JeanMichon,
      name: 'speaker.jeanmichon.name',
      company: 'speaker.jeanmichon.company',
      // job: 'Nguyên Trưởng khoa Nhi',
      // bio: 'speaker.jeanmichon.bio',
      // abstract: 'speaker.jeanmichon.abstract',
   },
   {
      img: ClaireAlapetile,
      name: 'speaker.clairealapetile.name',
      company: 'speaker.clairealapetile.company',
      // job: 'Nguyên Trưởng khoa Nhi',
      bio: 'speaker.clairealapetile.bio',
      abstract: 'speaker.clairealapetile.abstract',
   },
   {
      img: KathyPrichardJones,
      name: 'speaker.kathyprichardjones.name',
      company: 'speaker.kathyprichardjones.company',
      bio: 'speaker.kathyprichardjones.bio',
      // abstract: 'speaker.kathyprichardjones.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: SallyBlair,
      name: 'speaker.sallyblair.name',
      company: 'speaker.sallyblair.company',
      // bio: 'speaker.sallyblair.bio',
      // abstract: 'speaker.sallyblair.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: RodriguezGalindo,
      name: 'speaker.rodriguezgalindo.name',
      company: 'speaker.rodriguezgalindo.company',
      bio: 'speaker.rodriguezgalindo.bio',
      // abstract: 'speaker.rodriguezgalindo.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: IbrahimQaddoumi,
      name: 'speaker.ibrahimqaddoumi.name',
      company: 'speaker.ibrahimqaddoumi.company',
      bio: 'speaker.ibrahimqaddoumi.bio',
      // abstract: 'speaker.ibrahimqaddoumi.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: HirotoInaba,
      name: 'speaker.hirotoinaba.name',
      company: 'speaker.hirotoinaba.company',
      bio: 'speaker.hirotoinaba.bio',
      // abstract: 'speaker.hirotoinaba.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: PierreMary,
      name: 'speaker.pierremary.name',
      company: 'speaker.pierremary.company',
      // bio: 'speaker.pierremary.bio',
      // abstract: 'speaker.pierremary.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: NaylaNicolas,
      name: 'speaker.naylanicolas.name',
      company: 'speaker.naylanicolas.company',
      // bio: 'speaker.naylanicolas.bio',
      // abstract: 'speaker.naylanicolas.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: YiHeongGyu,
      name: 'speaker.yiheonggyu.name',
      company: 'speaker.yiheonggyu.company',
      bio: 'speaker.yiheonggyu.bio',
      abstract: 'speaker.yiheonggyu.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: PhanTruc,
      name: 'speaker.phantruc.name',
      company: 'speaker.phantruc.company',
      bio: 'speaker.phantruc.bio',
      abstract: 'speaker.phantruc.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: JuliaPalma,
      name: 'speaker.juliapalma.name',
      company: 'speaker.juliapalma.company',
      // bio: 'speaker.juliapalma.bio',
      // abstract: 'speaker.juliapalma.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: MichelPeuchmaur,
      name: 'speaker.michelpeuchmaur.name',
      company: 'speaker.michelpeuchmaur.company',
      bio: 'speaker.michelpeuchmaur.bio',
      abstract: 'speaker.michelpeuchmaur.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   // {
   //    img: TranThiHongHa,
   //    name: 'speaker.tranthihongha.name',
   //    company: 'speaker.tranthihongha.company',
   //    // bio: 'speaker.tranthihongha.bio',
   //    // abstract: 'speaker.tranthihongha.abstract',
   //    // job: 'Nguyên Trưởng khoa Nhi',
   // },
   {
      img: MichelleHermiston,
      name: 'speaker.michellehermiston.name',
      company: 'speaker.michellehermiston.company',
      bio: 'speaker.michellehermiston.bio',
      // abstract: 'speaker.michellehermiston.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: SelinaLuger,
      name: 'speaker.selinaluger.name',
      company: 'speaker.selinaluger.company',
      // bio: 'speaker.selinaluger.bio',
      abstract: 'speaker.selinaluger.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: ChetanDhamneTata,
      name: 'speaker.chetandhamnetata.name',
      company: 'speaker.chetandhamnetata.company',
      bio: 'speaker.chetandhamnetata.bio',
      // abstract: 'speaker.chetandhamnetata.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: ThuanChong,
      name: 'speaker.thuanchong.name',
      company: 'speaker.thuanchong.company',
      // bio: 'speaker.thuanchong.bio',
      // abstract: 'speaker.thuanchong.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   // {
   //    img: KiDongPark,
   //    name: 'speaker.kidongpark.name',
   //    company: 'speaker.kidongpark.company',
   //    // bio: 'speaker.kidongpark.bio',
   //    // abstract: 'speaker.kidongpark.abstract',
   //    // job: 'Nguyên Trưởng khoa Nhi',
   // },
   {
      img: TranTrungToan,
      name: 'speaker.trantrungtoan.name',
      company: 'speaker.trantrungtoan.company',
      bio: 'speaker.trantrungtoan.bio',
      abstract: 'speaker.trantrungtoan.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: DoHuyenNga,
      name: 'speaker.dohuyennga.name',
      company: 'speaker.dohuyennga.company',
      // bio: 'speaker.dohuyennga.bio',
      // abstract: 'speaker.dohuyennga.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: DoDungKien,
      name: 'speaker.dodungkien.name',
      company: 'speaker.dodungkien.company',
      bio: 'speaker.dodungkien.bio',
      // abstract: 'speaker.dodungkien.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: VuDinhQuang,
      name: 'speaker.vudinhquang.name',
      company: 'speaker.vudinhquang.company',
      bio: 'speaker.vudinhquang.bio',
      abstract: 'speaker.vudinhquang.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: PascalePhilippeChomette,
      name: 'speaker.pascalephilippechomette.name',
      company: 'speaker.pascalephilippechomette.company',
      bio: 'speaker.pascalephilippechomette.bio',
      // abstract: 'speaker.pascalephilippechomette.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: VietHuong,
      name: 'speaker.viethuong.name',
      company: 'speaker.viethuong.company',
      bio: 'speaker.viethuong.bio',
      // abstract: 'speaker.viethuong.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
]
const Speaker = memo(() => {
   const { translate } = useTranslation();
   return (
      <div className="common-layout">
         <div className="title" id="speaker">{translate('speaker.title')}</div>
         <Row gutter={[30, 40]} className="info-container">
            {
               speakerList.map((item, idx) => {
                  return (
                     <Col className="gutter-row" span={8} lg={8} md={12} sm={12} xs={24} key={idx}>
                        <InfoItem data={item} />
                     </Col>
                  )
               })
            }
         </Row>
      </div>
   )
});

export default Speaker;