import React from 'react';

const Calendar = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M1 6h14M5 .5v1M11 .5v1M.5 5.5A3.5 3.5 0 014 2h8a3.5 3.5 0 013.5 3.5V12a3.5 3.5 0 01-3.5 3.5H4A3.5 3.5 0 01.5 12V5.5z"
         stroke="#555"
         strokeLinecap="round"
         strokeLinejoin="round"
      />
   </svg>
);

export default Calendar;