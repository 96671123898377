import React, { memo, useContext } from 'react';
import Context from '../translation/components/Context';
import { Select } from 'antd';

const options = [
   {
      label: 'ENGLISH',
      value: 'en'
   },
   {
      label: 'TIẾNG VIỆT',
      value: 'vi'
   }
]
const LanguageDropdown = memo(({ onChange }) => {
   const { resource, changeLanguage, lang } = useContext(Context);
   const handleChangeLanguage = (value) => {
      changeLanguage(value);
      onChange && onChange();
   }

   return (
      <Select
         showSearch
         style={{ width: 120 }}
         placeholder="Search to Select"
         options={options}
         onChange={handleChangeLanguage}
         value={lang}
         className="select-language"
      />
   );
});

export default LanguageDropdown;