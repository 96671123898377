import React from 'react';

const Calendar = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M15 1L1 15M1 1l14 14"
         stroke="#000"
         strokeWidth={1.5}
         strokeMiterlimit={10}
         strokeLinejoin="round"
      />
   </svg>
);

export default Calendar;