import React, { Fragment, memo, useContext } from 'react';
import { convertToTime } from './constants';
import Context from '../../translation/components/Context';
const Seminor = memo(({ list }) => {
   const { resource, changeLanguage, lang } = useContext(Context);
   const renderTime = (data) => {
      if (data?.actual_started_at && data?.actual_ended_at) {
         return (
            `${convertToTime(data?.actual_started_at)} - ${convertToTime(data?.actual_ended_at)}`
         )
      }
   }

   const renderName = (data) => {
      if (lang === 'en') {
         return `${data?.name_en}`
      } else {
         return `${data?.name}`
      }
   }

   const renderDescription = (data) => {
      if (lang === 'en') {
         return `${data?.description_en}`
      } else {
         return `${data?.description}`
      }
   }
   return (
      <div className="expand-container">
         {
            list && list.length !== 0 && list.map((item, idx) => {
               return (
                  <div className="info-row" key={idx}>
                     <div className="icon-circel"></div>
                     <div className="session-content-item">
                        <div>
                           <span className="time">{renderTime(item)}</span><span className="dot">・</span>
                           <span className="label">{renderName(item)}</span>
                        </div>
                        <div className="description-seminor">{renderDescription(item)}</div>
                     </div>
                  </div>
               )
            })
         }
      </div>
   );
});

export default Seminor;