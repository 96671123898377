import React, { memo, useState } from 'react';
import { Popover } from 'antd';
import useTranslation from '../../translation/components/useTranslation';

const InfoItem = memo(({ data }) => {
   const { translate } = useTranslation();
   const [visible, setVisible] = useState(false);

   const renderContent = () => {
      return (
         <div className="popover-bio">
            <div className="header">
               {
                  data?.img &&
                  <img src={data.img} alt="" className="img" />
               }
               <div>
                  <div className="name">{translate(data.name)}</div>
                  {/* <div>{translate(data.company)}</div> */}
                  <div dangerouslySetInnerHTML={{ __html: translate(data?.company) }}></div>
               </div>
            </div>
            {
               data?.bio &&
               <>
                  {/* <div className="bio">{translate('speaker.bio')}</div> */}
                  <div className="bio"></div>
                  {/* <div>{translate(data.bio)}</div> */}
                  <div dangerouslySetInnerHTML={{ __html: translate(data?.bio) }}></div>
               </>
            }
            {
               data?.abstract &&
               <>
                  <div className="bio">{translate('speaker.abstract')}</div>
                  {/* <div>{translate(data.abstract)}</div> */}
                  <div dangerouslySetInnerHTML={{ __html: translate(data?.abstract) }}></div>
               </>
            }
         </div>
      )
   };

   const handleVisibleChange = () => {
      setVisible(!visible);
   };
   return (
      <Popover
         content={renderContent()}
         trigger="click"
         visible={visible}
         placement="bottom"
         onVisibleChange={handleVisibleChange}
      >
         <div className="col-item" >
            {
               data?.img &&
               <img src={data.img} alt="" className="img" />
            }
            <div className="owner-info">
               <div className="name">{translate(data.name)}</div>
               <div dangerouslySetInnerHTML={{ __html: translate(data?.company) }}></div>
               {/* <div className="company-name">{translate(data.company)}</div> */}
               {/* <div className="job">{data.job}</div> */}
            </div>
         </div>
      </Popover>
   );
});

export default InfoItem;