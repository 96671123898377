import Cookies from 'js-cookie';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { config, COOKIE_LANGUAGE_NAME } from '../constant';
import { setLangCodeToUserStorage } from '../function';
import Context from './Context';

const Localization = memo((props) => {
   const [data, setData] = useState({
      namespace: '',
      lang: '',
      resource: {},
   });

   useEffect(() => {
      let language = '';
      let resource = null;
      const namespace = config.defaultNamespace;

      /**
       * temporary comment this functions. Wait for english language translated
       */
      let currentUserLanguage = Cookies.get(COOKIE_LANGUAGE_NAME);
      // get saved language
      if (currentUserLanguage) {
         language = currentUserLanguage;
         resource = config.resources[currentUserLanguage];
      }
      if (!resource) {
         // get user's local language
         let userLocalLanguage = window?.navigator?.language;
         if (userLocalLanguage) {
            for (const langCode in config.resources) {
               if ((new RegExp(`^${langCode}\\b`)).test(userLocalLanguage)) {
                  language = langCode;
                  resource = config.resources[langCode];
                  break;
               }
            }
         }
      }


      if (!resource && config.resources[config.defaultLanguage]) {
         // get default language
         language = config.defaultLanguage;
         resource = config.resources[config.defaultLanguage];
      }

      if (resource) {
         resource = resource[namespace];
      }

      setData({
         namespace,
         lang: language || '',
         resource: resource || {},
      });
      setLangCodeToUserStorage(language || '');
   },
      []
   )

   const changeLanguage = useCallback((newLangCode) => {
      const newResource = config.resources?.[newLangCode]?.[data.namespace];
      if (newResource) {
         setData({
            ...data,
            lang: newLangCode,
            resource: newResource,
         });
         setLangCodeToUserStorage(newLangCode);
      }
   },
      [data]
   )

   return (
      <Context.Provider value={{
         lang: data.lang,
         resource: data.resource,
         changeLanguage: changeLanguage,
      }}>
         {props.children}
      </Context.Provider>
   )
})

export default Localization;