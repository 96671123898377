import { Col, Row } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import IconWho from '../../assets/images/who_partner.svg';
import WhoImgInfo from '../../assets/images/seminor_2.svg';
import { useParams } from 'react-router';
import IconCuire from '../../assets/images/curie_partner.svg';
import IconStJude from '../../assets/images/stjude_partner.svg';
import LeVanQuang from "../../assets/images/le_van_quang.svg";
import ChauDangHa from "../../assets/images/chau_dang_ha.svg";
import PhanThiXinh from "../../assets/images/phan_thi_xinh.svg";
import JeanMichon from "../../assets/images/jean_michon.svg";
import NaylaNicolas from "../../assets/images/nayla_nicolas.svg";
import ClaireAlapetile from "../../assets/images/claire_alapetile.svg";
import KiDongPark from "../../assets/images/ki_dong_park.png";
import SallyBlair from "../../assets/images/sally_blair.svg";
import { services } from '../../services/Services';
import Header from '../Header';
import useTranslation from '../../translation/components/useTranslation';
import HirotoInaba from "../../assets/images/hiroto_inaba.svg";
import IbrahimQaddoumi from "../../assets/images/ibrahim_qaddoumi.svg";
import RodriguezGalindo from "../../assets/images/rodriguez_galindo.svg";

const who = {
   logo: IconWho,
   img: WhoImgInfo,
   bio: 'partner.who.bio',
   name: 'who',
   experts: [
      {
         img: KiDongPark,
         name: 'speaker.kidongpark.name',
         company: 'speaker.kidongpark.company',
      },
   ]
};

const curieInstitute = {
   logo: IconCuire,
   img: WhoImgInfo,
   bio: 'partner.cuire.bio',
   name: 'cuire',
   experts: [
      {
         img: ClaireAlapetile,
         name: 'speaker.clairealapetile.name',
         company: 'speaker.clairealapetile.company',
      },
      {
         img: NaylaNicolas,
         name: 'speaker.naylanicolas.name',
         company: 'speaker.naylanicolas.company',
      },
      {
         img: JeanMichon,
         name: 'speaker.jeanmichon.name',
         company: 'speaker.jeanmichon.company',
         // abstract: 'speaker.jeanmichon.abstract',
      },
   ]
};

const stJude = {
   logo: IconStJude,
   img: WhoImgInfo,
   bio: 'partner.stjude.bio',
   name: 'stjude',
   experts: [
      {
         img: SallyBlair,
         name: 'speaker.sallyblair.name',
         company: 'speaker.sallyblair.company',
      },
      {
         img: HirotoInaba,
         name: 'speaker.hirotoinaba.name',
         company: 'speaker.hirotoinaba.company',
      },
      {
         img: IbrahimQaddoumi,
         name: 'speaker.ibrahimqaddoumi.name',
         company: 'speaker.ibrahimqaddoumi.company',
      },
      {
         img: RodriguezGalindo,
         name: 'speaker.rodriguezgalindo.name',
         company: 'speaker.rodriguezgalindo.company',
      },

   ]
};
const PartnerDetails = memo((props) => {
   const [dataHeader, setDataHeader] = useState([]);
   const { translate } = useTranslation();

   const params = useParams();
   const [data, setData] = useState(null);
   useEffect(() => {
      window.scrollTo(0, 0);
      if (params?.code === 'who') {
         setData(who);
      } else if (params?.code === 'curie-institute') {
         setData(curieInstitute);
      } else if (params?.code === 'st-jude') {
         setData(stJude);
      }
      const getSessonList = async () => {
         let formData = new FormData();
         formData.append('webinar_code', 'BVK');
         let response = await services.getSessonList(formData);
         if (response.isSuccess) {
            setDataHeader(response.data);
         }
      };
      getSessonList();
   }, [params?.code])
   console.log(params);
   console.log(data);

   const renderBackground = () => {
      if (data?.name === 'cuire') {
         return 'cuire';
      } else if (data?.name === 'stjude') {
         return 'stjude';
      }
   }

   return (
      <>
         <Header data={dataHeader} />
         <div className={`common-layout introduction partner ${renderBackground()}`}>
            <img src={data?.logo} alt={data?.bio} className="img-partner-details" />
         </div>
         <div className="common-layout">
            <Row className="info-partner" gutter={{ md: 48, sm: 0, xs: 0 }}>
               <Col span={12} md={12} sm={24} xs={24} >
                  <img className="img" src={data?.img} alt={data?.bio} />
               </Col>
               <Col span={12} md={12} sm={24} xs={24}>
                  <div className="info-title">{translate("partner.intro")}</div>
                  <div className="info-content"><div dangerouslySetInnerHTML={{ __html: translate(data?.bio) }}></div></div>
               </Col>
            </Row>
         </div>
         <div className="common-layout partner-experts" >
            <div className="experts-title">{translate("partner.experts")}</div>
            <Row gutter={[16, 24]} className="info-container">
               {
                  data?.experts.map((item, idx) => {
                     return (
                        <Col className="gutter-row" span={8} lg={8} md={12} sm={24} xs={24} key={idx}>
                           <div className="col-item" >
                              <img src={item.img} alt="" className="img" />
                              <div className="owner-info">
                                 <div className="name">{translate(item.name)}</div>
                                 <div className="company-name">{translate(item.company)}</div>
                              </div>
                           </div>
                        </Col>
                     )
                  })
               }
            </Row>
         </div>
      </>
   );
});

export default PartnerDetails;