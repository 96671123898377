import React, { memo, useEffect, useState } from 'react';
import { services } from '../services/Services';
import Contact from '../view/Contact';
import OwnerInfo from '../view/info/OwnerInfo';
import Speaker from '../view/info/Speaker';
import Introduction from '../view/Introduction';
import PartnerList from '../view/partner/PartnerList';
import RegisterForm from '../view/RegisterForm';
import SeminorProgram from '../view/SeminorProgram';
import Header from './Header';
import OrganizingCommittee from './info/OrganizingCommittee';
import { repeatApiTime } from './shedules/constants';

const DefaultLayout = memo(() => {
   const [data, setData] = useState([]);
   const time = repeatApiTime;
   useEffect(() => {
      const getSessonList = async () => {
         let formData = new FormData();
         formData.append('webinar_code', 'BVK');
         let response = await services.getSessonList(formData);
         if (response.isSuccess) {
            setData(response.data);
         }
      };
      getSessonList();
      let repeatInvokeApi = setInterval(() => { getSessonList() }, time);
      return () => clearInterval(repeatInvokeApi);
   }, []);
   console.log(data);
   return (
      <div>
         <Header data={data} />
         <Introduction data={data} />
         <SeminorProgram data={data} />
         <OrganizingCommittee />
         <OwnerInfo />
         <Speaker />
         <PartnerList />
         <Contact />
         {
            data?.status === 'NOT_STARTED' &&
            <RegisterForm />
         }
      </div>
   );
});

export default DefaultLayout;