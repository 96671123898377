import React, { memo } from 'react';
import LogoFooter from '../icons/LogoFooter';
import useTranslation from '../translation/components/useTranslation';

const Footer = memo(() => {
   const { translate } = useTranslation();
   return (
      <div className="common-layout">
         <div className="footer-container">
            <div>
               <span>{translate('footer.text_1')}</span>
               {/* <span className="line">|</span>
               <span>Chính sách bảo mật</span>
               <span className="line">|</span>
               <span>Điều khoản & điều kiện</span>
               <span className="line">|</span> */}
            </div>
            <div className="content-left"><span className="heart">{translate('footer.text_2')}</span><a href="https://horusoftaceae.com/"><LogoFooter /></a></div>
         </div>
      </div>
   );
});

export default Footer;