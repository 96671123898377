import { Col, Row } from 'antd';
import React, { memo } from 'react';
import useTranslation from '../translation/components/useTranslation';

const Contact = memo(() => {
   const { translate } = useTranslation();
   const contactList = [
      {
         branch: translate('contact.campus_1'),
         phone: '0904748808',
         email: 'benhvienk.project@gmail.com',
         address: translate('contact.address_1'),
      },
      {
         branch: translate('contact.campus_2'),
         phone: '0936238808',
         email: 'benhvienk.project@gmail.com',
         address: translate('contact.address_2'),
      },
      {
         branch: translate('contact.campus_3'),
         phone: '0904748808',
         email: 'benhvienk.project@gmail.com',
         address: translate('contact.address_3'),
      },
   ]
   return (
      <div className="common-layout">
         <div className="title" id="contact">{translate('contact.title')}</div>
         <Row gutter={[16, 24]} className="info-container contact">
            {
               contactList.map((item, idx) => {
                  return (
                     <Col className="gutter-row" span={8} lg={8} md={12} sm={12} xs={24} key={idx}>
                        <div className="col-item">
                           <div className="owner-info">
                              <div className="name">{item.branch}</div>
                              <div className="company-name">{item.phone}</div>
                              <div className="company-name">{item.email}</div>
                              <div className="job">{item.address}</div>
                           </div>
                        </div>
                     </Col>
                  )
               })
            }
         </Row>
      </div>
   );
});

export default Contact;