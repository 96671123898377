import React from 'react';

const Collapse = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M3.931 5a.8.8 0 00-.565 1.366l4.068 4.068a.8.8 0 001.132 0l4.068-4.068A.8.8 0 0012.07 5H3.93z"
         fill="#fff"
      />
   </svg>
);

export default Collapse;