import { Col, Row } from 'antd';
import React, { memo } from 'react';
import JeanMichon from "../../assets/images/jean_michon.svg";
import LeVanQuang from "../../assets/images/le_van_quang.svg";
import PhanThiXinh from "../../assets/images/phan_thi_xinh.svg";
import TranNgocSon from "../../assets/images/tran_ngoc_son.svg";
import InfoItem from './InfoItem';
import NguyenThanhLiem from "../../assets/images/nguyen_thanh_liem.svg";
import TranTrungDung from "../../assets/images/tran_trung_dung.svg";
import TranThiKieuMy from "../../assets/images/tran_thi_kieu_my.svg";
import BuiVanGiang from "../../assets/images/bui_van_giang.svg";
import PhanCanhDuy from "../../assets/images/phan_canh_duy.svg";
import MichelleHermiston from "../../assets/images/michelle_hermiston.svg";
import YiHeongGyu from "../../assets/images/yi_heong_gyu.svg";
import NguyenThiHongVan from "../../assets/images/nguyen_thi_hong_van.svg";
import useTranslation from '../../translation/components/useTranslation';
import HuynhDucVinhPhu from "../../assets/images/huynh_duc_vinh_phu.png";
import KiDongPark from "../../assets/images/ki_dong_park.png";

const ownerList = [
   {
      img: LeVanQuang,
      name: 'owner.levanquang.name',
      company: 'owner.levanquang.company',
      bio: 'owner.levanquang.bio',
   },
   {
      img: KiDongPark,
      name: 'speaker.kidongpark.name',
      company: 'speaker.kidongpark.company',
      bio: 'speaker.kidongpark.bio',
      // abstract: 'speaker.kidongpark.abstract',
      // job: 'Nguyên Trưởng khoa Nhi',
   },
   {
      img: NguyenThanhLiem,
      name: 'owner.nguyenthanhliem.name',
      company: 'owner.nguyenthanhliem.company',
      // bio: 'owner.nguyenthanhliem.bio',
   },
   {
      img: TranNgocSon,
      name: 'owner.tranngocson.name',
      company: 'owner.tranngocson.company',
      bio: 'owner.tranngocson.bio',
   },
   {
      img: JeanMichon,
      name: 'owner.jeanmichon.name',
      company: 'owner.jeanmichon.company',
      bio: 'owner.jeanmichon.bio',
   },
   {
      img: TranTrungDung,
      name: 'owner.trantrungdung.name',
      company: 'owner.trantrungdung.company',
      bio: 'owner.trantrungdung.bio',
   },
   {
      img: TranThiKieuMy,
      name: 'owner.tranthikieumy.name',
      company: 'owner.tranthikieumy.company',
      // bio: 'owner.tranthikieumy.bio',
   },
   {
      img: BuiVanGiang,
      name: 'owner.buivangiang.name',
      company: 'owner.buivangiang.company',
      bio: 'owner.buivangiang.bio',
   },
   {
      img: PhanCanhDuy,
      name: 'owner.phancanhduy.name',
      company: 'owner.phancanhduy.company',
      bio: 'owner.phancanhduy.bio',
   },
   {
      img: MichelleHermiston,
      name: 'owner.michellehermiston.name',
      company: 'owner.michellehermiston.company',
      // bio: 'owner.michellehermiston.bio',
   },
   {
      img: PhanThiXinh,
      name: 'owner.phanthixinh.name',
      company: 'owner.phanthixinh.company',
      bio: 'owner.phanthixinh.bio',
   },
   {
      img: YiHeongGyu,
      name: 'owner.yiheonggyu.name',
      company: 'owner.yiheonggyu.company',
      bio: 'owner.yiheonggyu.bio',
   },
   {
      img: NguyenThiHongVan,
      name: 'owner.nguyenthihongvan.name',
      company: 'owner.nguyenthihongvan.company',
      bio: 'owner.nguyenthihongvan.bio',
   },
   {
      img: HuynhDucVinhPhu,
      name: 'owner.huynhDucVinhPhuc.name',
      company: 'owner.huynhDucVinhPhuc.company',
      // bio: 'owner.nguyenthhuynhDucVinhPhucihongvan.bio',
   },
]

const OwnerInfo = memo(() => {
   const { translate } = useTranslation();
   return (
      <div className="common-layout" >
         <div className="title" id="owner-info">{translate('owner.title')}</div>
         <Row gutter={[30, 40]} className="info-container">
            {
               ownerList.map((item, idx) => {
                  return (
                     <Col className="gutter-row" span={8} lg={8} md={12} sm={24} xs={24} key={idx}>
                        <InfoItem data={item} />
                     </Col>
                  )
               })
            }
         </Row>
      </div>
   );
});

export default OwnerInfo;