import React from 'react';

const IconInprocess = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon-inprocess"
   >
      <circle cx={8} cy={8} r={7} stroke="#FF6961" strokeWidth={2} />
      <circle cx={8} cy={8} r={4} fill="#FF6961" />
   </svg>
);

export default IconInprocess;