import React, { memo } from 'react';
import { Dropdown, Statistic, Menu } from 'antd';
import { deadline } from './shedules/constants';
import IconInprocess from '../icons/IconInprocess';
import useTranslation from '../translation/components/useTranslation';

const { Countdown } = Statistic;
const Introduction = memo(({ data }) => {
   const { translate } = useTranslation();
   const handleClickItem = (id) => {
      let element = document.getElementById(id);
      if (element) {
         element.scrollIntoView({ behavior: "smooth" });
      }
   }

   const renderStatusButton = () => {
      let statusCode = data?.status;
      if (statusCode === 'NOT_STARTED') {
         return <span className="header-button" onClick={() => handleClickItem('register')}>{translate('register.title')}</span>;
      } else if (statusCode === 'IN_PROGRESS') {
         const listSessions = data?.active_sessions;
         if (listSessions && listSessions.length > 1) {
            const menu = (
               <Menu className="menu-sessions">
                  {
                     listSessions.map((session, idx) => {
                        return (
                           <Menu.Item key={idx}>
                              <a href={session.joining_url} target="_blank" rel="noreferrer"><span>{session.title}. {session.name}</span></a>
                           </Menu.Item>
                        )
                     })
                  }
               </Menu>
            );
            return (
               <Dropdown overlay={menu} trigger={['click']}>
                  <div className="header-button inprocess"><IconInprocess />{translate('status.in_process')}</div>
               </Dropdown>
            )
         } else if (listSessions && listSessions.length === 1) {
            return <a href={listSessions[0]?.joining_url} target="_blank" rel="noreferrer"><div className="header-button inprocess"><IconInprocess />{translate('status.in_process')}</div></a>;
         } else {
            return <div className="header-button inprocess"><IconInprocess />{translate('status.in_process')}</div>;
         }
      } else if (statusCode === 'COMPLETED') {
         return <a href={'https://nci.elitelearning.vn/'} target="_blank" rel="noreferrer"><span className="header-button">{translate('status.completed')}</span></a>;
      }
   }

   return (
      <div className="introduction-container">
         <div className="common-layout introduction">
            <div className="introduction-content" id="introduction">
               <div className="introduction-main">
                  <div className="content">
                     <div className="text-1">{translate('introduction.title')}
                     </div>
                     <div className="text-2">
                        {translate('introduction.label_1')}<br className="break-row" />{translate('introduction.label_2')}</div>
                     <div className="time-row">
                        {/* <div className="time-total">
                           <div className="time">01</div>
                           <div className="unit">Ngày</div>
                        </div>
                        <div className="time-total">
                           <div className="time">06</div>
                           <div className="unit">Giờ</div>
                        </div>
                        <div className="time-total">
                           <div className="time">24</div>
                           <div className="unit">Phút</div>
                        </div> */}
                        {
                           data?.status === 'NOT_STARTED' &&
                           <div>
                              <Countdown
                                 value={deadline}
                                 format="D&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;H&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;m"
                              />
                              <div className="format-countdown">
                                 <span className="date">{translate('introduction.date')}</span>
                                 <span className="hours">{translate('introduction.hours')}</span>
                                 <span className="mins">{translate('introduction.mins')}</span>
                              </div>
                           </div>
                        }
                        <div>
                           {renderStatusButton()}
                        </div>
                     </div>
                  </div>
               </div>
               <div className="introduction-summary">
                  <div className="content">
                     <div className="label">{translate('introduction.intro')}</div>
                     <div className="text">{translate('introduction.text_1')}
                     </div>
                     <div className="text">{translate('introduction.text_2')}
                     </div>
                     <div className="text">{translate('introduction.text_3')}</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
});

export default Introduction;