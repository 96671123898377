import React, { memo, useState, useContext } from 'react';
import Calendar from '../icons/Calendar';
import Clock from '../icons/Clock';
import Expand from '../icons/Expand';
import Collapse from '../icons/Collapse';
import Seminor from './shedules/Seminor';
import { convertToDate, convertToTime, seminor1 } from './shedules/constants';
import { seminor2 } from './shedules/constants';
import { seminor3 } from './shedules/constants';
import { seminor4 } from './shedules/constants';
import { seminor5 } from './shedules/constants';
import IconInprocess from '../icons/IconInprocess';
import useTranslation from '../translation/components/useTranslation';
import Context from '../translation/components/Context';

const SeminorItem = memo(({ data }) => {
   const { resource, changeLanguage, lang } = useContext(Context);
   const { translate } = useTranslation();
   const [isExpand, setIsExpand] = useState(false);

   const handleExpand = () => {
      setIsExpand(!isExpand);
   };

   const renderContent = () => {
      let session = data?.session;
      if (session === "phien_1") {
         return <Seminor list={seminor1} />
      } else if (session === "phien_2") {
         return <Seminor list={seminor2} />
      } else if (session === "phien_3") {
         return <Seminor list={seminor3} />
      } else if (session === "phien_4") {
         return <Seminor list={seminor4} />
      } else if (session === "phien_5") {
         return <Seminor list={seminor5} />
      } else {
         return null;
      }
   }

   const renderTime = () => {
      if (data?.actual_started_at && data?.actual_ended_at) {
         return (
            `${convertToTime(data?.actual_started_at)} - ${convertToTime(data?.actual_ended_at)}`
         )
      }
   };

   const renderStatus = () => {
      let status = data?.status;
      if (status === 'NOT_STARTED') {
         return <a href={data?.joining_url} target="_blank" rel="noreferrer"><div className="status-button">{translate('status.not_start')}</div></a>;
      } else if (status === 'IN_PROGRESS') {
         return <a href={data?.joining_url} target="_blank" rel="noreferrer"><div className="status-button inprocess"><IconInprocess />{translate('status.in_process')}</div></a>;
      } else if (status === 'COMPLETED') {
         return <a href={data?.joining_url} target="_blank" rel="noreferrer"><div className="status-button finished">{translate('status.session_completed')}</div></a>;
      }
   }

   const renderTitle = () => {
      if (lang === 'en') {
         if (data?.title_en) {
            return `${data?.title_en}. ${data?.name_en}`;
         } else {
            return `${data?.name_en}`;
         }
      } else {
         if (data?.title) {
            return `${data?.title}. ${data?.name}`;
         } else {
            return `${data?.name}`;
         }
      }
   }

   const renderDescription = () => {
      if (lang === 'en') {
         return `${data?.description_en}`
      } else {
         return `${data?.description}`
      }
   }

   return (
      <div>
         <div className={`seminor-item ${isExpand ? 'expand' : ''}`}>
            <div className="img-content">
               <img src={data?.logo} alt={data?.label} className="img-seminor" />
            </div>
            <div className="info">
               <div className="header">
                  <div className="right">{renderTitle()}</div>
                  <div className="left">
                     <span className="icon-time">
                        <div className="icon"><Calendar /></div>
                     </span>{data?.actual_started_at && convertToDate(data?.actual_started_at)}
                     <span className="icon-time">
                        <div className="icon"><Clock /></div>
                     </span>{renderTime()}
                  </div>
               </div>
               <div className="description">{renderDescription()}
               </div>
               <div className="button-row">
                  {renderStatus()}
                  {
                     isExpand ?
                        <div className="view-content hide-content" onClick={handleExpand}><Collapse />{translate('agenda.hide_content')}</div>
                        :
                        <div className="view-content" onClick={handleExpand}><Expand />{translate('agenda.show_content')}</div>
                  }
               </div>
            </div>
         </div>
         {
            // isExpand && renderContent()
            isExpand &&
            <Seminor list={data?.session_content} />
         }
      </div>
   );
});

export default SeminorItem;