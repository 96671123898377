import React, { Fragment, memo, useEffect, useState } from 'react';
import SeminorItem from './SeminorItem';
import Seminor1 from '../assets/images/seminor_1.svg';
import Seminor2 from '../assets/images/seminor_2.svg';
import Seminor3 from '../assets/images/seminor_3.svg';
import Seminor4 from '../assets/images/seminor_4.svg';
import Seminor5 from '../assets/images/seminor_5.svg';
import { services } from '../services/Services';
import useTranslation from '../translation/components/useTranslation';


const listSeminor = [
   {
      image: Seminor1,
      label: 'Phiên 1. Phiên toàn thể',
      date: '1/12/2021',
      time: '8:00 - 10:20',
      description: 'Chủ tọa: PGS TS Lê Văn Quảng - Giám đốc Bệnh viện K・Đại diện Bệnh viện Ung bướu Hồ Chí Minh・BSCKII Châu Đăng Hà- Giám đốc trung tâm Nhi khoa, Bệnh viện TW Huế',
      status: 'Sắp diễn ra',
      session: 'phien_1',
   },
   {
      image: Seminor2,
      label: 'Phiên 2. Di truyền học phân tử',
      date: '1/12/2021',
      time: '10:20 - 11:45',
      description: 'Chủ tọa: PGS.TS Phan Thị Xinh (Trưởng khoa Di truyền tế bào - Sinh học phân tử, BV Truyền máu Huyết học TP. HCM, Phó chủ nhiệm bộ môn Huyết học, ĐH Y Dược Tp HCM)・TS.BS Ngô Diễm Ngọc (Trưởng khoa di truyền hoc phân tử - BV Nhi TW)・PGS.TS Tạ Văn Tờ, PGS.TS Tạ Văn Tờ (Giám đốc Trung tâm Giải phẫu bệnh và sinh học phân tử,  Bệnh viện K)',
      status: 'Sắp diễn ra',
      session: 'phien_2',
   },
   {
      image: Seminor3,
      label: 'Phiên 3. Cập nhật chẩn đoán và điều trị u đặc',
      date: '1/12/2021',
      time: '13:30 - 17:00',
      description: "Chủ toạ: GS.TS Jean Michon (Nguyên Trưởng khoa Nhi, Viện Curie, Pháp)・PGS.TS Trần Ngọc Sơn(Phó giám đốc Bệnh viện Xanh Pôn)",
      status: 'Sắp diễn ra',
      session: 'phien_3',
   },
   {
      image: Seminor4,
      label: 'Phiên 4. Cập nhật chẩn đoán và điều trị u hệ thống thần kinh TW và u xương ác tính',
      date: '1/12/2021',
      time: '13:30 - 16:30',
      description: "Chủ toạ: PGS TS Ngô Thanh Tùng (Giám đốc Trung tâm xạ trị, Bệnh viện K)・TS.BS. Phan Cảnh Duy (Phó Giám đốc Trung tâm Ung bướu, BV Trung ương Huế)・TS.BS Nguyễn Đức Liên (Trưởng Khoa Ngoại Thần kinh, Bệnh viện K)",
      status: 'Sắp diễn ra',
      session: 'phien_4',
   },
   {
      image: Seminor5,
      label: 'Phiên 5. Cập nhật chẩn đoán và điều trị ung thư huyết học',
      date: '2/12/2021',
      time: '9:00 - 11:30',
      description: 'Chủ tọa: PGS.TS Nguyễn Hà Thanh (Phó Giám đốc Bệnh viện Huyết học truyền máu TW)・TS.BS Trần Thị Hồng Hà (Nguyên trưởng khoa huyết học- BV Nhi TW)・BS.CK II Nguyễn Thị Diễm Chi (Bệnh viện TW Huế)',
      status: 'Sắp diễn ra',
      session: 'phien_5',
   },
]
const SeminorProgram = memo(({ data }) => {
   const { translate } = useTranslation();
   return (
      <div className="common-layout">
         <div className="seminor-program">
            <div className="title" id="seminor-program">{translate('agenda.title')}</div>
            {
               data?.status === 'IN_PROGRESS' &&
               <div className="text-inprocess">{translate('agenda.hint_1')}<span>{translate('agenda.hint_2')}</span>{translate('agenda.hint_3')}</div>
            }

            {/* {
               listSeminor.map((item, idx) => {
                  return (
                     <Fragment key={idx}>
                        <SeminorItem data={item} />
                     </Fragment>
                  )
               })
            } */}
            {
               data.length !== 0 && data?.sessions.map((session, idx) => {
                  return (
                     <Fragment key={idx}>
                        <SeminorItem data={session} />
                     </Fragment>
                  )
               })
            }
         </div>
      </div>
   );
});

export default SeminorProgram;