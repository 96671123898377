class ResponseModel {
   constructor() {
      this.isSuccess = false;
      this.errorCode = 0;
      this.message = '';
      this.data = null;
      this.currentTime = null;
   }
}

export default ResponseModel;