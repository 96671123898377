import React, { memo, useState } from 'react';
import { Form, Input, Button, Row, Col, notification } from 'antd';
import { services } from '../services/Services';
import useTranslation from '../translation/components/useTranslation';

const RegisterForm = memo(() => {
   const [isSubmitting, setIsSubmitting] = useState(false);
   const { translate } = useTranslation();
   const openNotification = placement => {
      notification.info({
         message: translate('register_form.noti_title'),
         description:
            translate('register_form.noti_content'),
         placement,
      });
   };

   const onFinish = async (values) => {
      await setIsSubmitting(true);
      console.log('Success:', values);
      let formData = new FormData();
      formData.append('webinar_code', 'BVK');
      formData.append('email', values?.email);
      formData.append('phone', values?.phone);
      formData.append('work_place', values?.company);
      formData.append('profession', values.profession);
      formData.append('specialized', values?.specialized);
      formData.append('full_name', values?.name);
      let response = await services.register(formData);
      if (response) { // BE yeu cau cu thong bao thanh cong, ko hien thong bao loi
         openNotification('topRight');
      }
      await setIsSubmitting(false);
   };

   return (
      <div className="common-layout">
         <div className="register-container" id="register">
            <div className="header">
               <div className="title">{translate('register_form.title')}</div>
               <div className="text">{translate('register_form.text')}</div>
            </div>
            <div className="form-container">
               <Form
                  onFinish={onFinish}
               >
                  <Row gutter={24}>
                     <Col span={12} sm={12} xs={24}>
                        <div className="label">{translate('register_form.name')}</div>
                        <Form.Item
                           name="name"
                           rules={[{
                              required: true,
                              message: translate('register_form.name_err'),
                           }]}
                        >
                           <Input
                              placeholder={translate('register_form.name')}
                              className="input-common"
                           />
                        </Form.Item>
                     </Col>
                     <Col span={12} sm={12} xs={24}>
                        <div className="label">{translate('register_form.profession')}</div>
                        <Form.Item
                           name="profession"
                           rules={[{ required: true, message: translate('register_form.profession_err') }]}
                        >
                           <Input
                              placeholder={translate('register_form.profession')}
                              className="input-common"
                           />
                        </Form.Item>
                     </Col>
                     <Col span={12} sm={12} xs={24}>
                        <div className="label">Email</div>
                        <Form.Item
                           name="email"
                           rules={[
                              { required: true, message: translate('register_form.email_err') },
                              {
                                 pattern: /\S+@\S+\.\S+/,
                                 message: 'Email không hợp lệ!',
                              }
                           ]}
                        >
                           <Input
                              placeholder="Email"
                              className="input-common"
                           />
                        </Form.Item>
                     </Col>
                     <Col span={12} sm={12} xs={24}>
                        <div className="label">{translate('register_form.phone')}</div>
                        <Form.Item
                           name="phone"
                           rules={[{ required: true, message: translate('register_form.phone_err') }]}
                        >
                           <Input
                              placeholder={translate('register_form.phone')}
                              className="input-common"
                              type="number"
                           />
                        </Form.Item>
                     </Col>
                     <Col span={12} sm={12} xs={24}>
                        <div className="label">{translate('register_form.company')}</div>
                        <Form.Item
                           name="company"
                           rules={[{ required: true, message: translate('register_form.company_err') }]}
                        >
                           <Input
                              placeholder={translate('register_form.company')}
                              className="input-common"
                           />
                        </Form.Item>
                     </Col>
                     <Col span={12} sm={12} xs={24}>
                        <div className="label">{translate('register_form.specialized')}</div>
                        <Form.Item
                           name="specialized"
                           rules={[{ required: true, message: translate('register_form.specialized_err') }]}
                        >
                           <Input
                              placeholder={translate('register_form.specialized')}
                              className="input-common"
                           />
                        </Form.Item>
                     </Col>
                  </Row>
                  <Form.Item>
                     <Button type="primary" htmlType="submit" className="register-button" disabled={isSubmitting}>
                        {translate('register.title')}
                     </Button>
                  </Form.Item>
               </Form>
            </div>
         </div>
      </div>
   );
});

export default RegisterForm;