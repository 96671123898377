import React, { useCallback, useContext } from "react";
import { getTranslatedText } from "../function";
import Context from "./Context";

const useTranslation = () => {
   const { resource, changeLanguage } = useContext(Context);

   const translate = useCallback((transKey, params) => {
      return getTranslatedText(transKey, params, resource);
   }
      ,
      [resource]
   )

   return {
      translate,
      changeLanguage,
   };
}

export default useTranslation;