import { Col, Row } from 'antd';
import React, { memo } from 'react';
import LeVanQuang from "../../assets/images/le_van_quang.svg";
import DoCamThanh from "../../assets/images/do_cam_thanh.svg";
import DoAnhTu from "../../assets/images/do_anh_tu.svg";
import NguyenDucLien from "../../assets/images/nguyen_duc_lien.svg";
import TranThiThanhHuong from "../../assets/images/tran_thi_thanh_huong.svg";
import PhanVanBinh from "../../assets/images/phan_van_binh.svg";
import NguyenTienQuang from "../../assets/images/nguyen_tien_quang.svg";
import TranVanThuan from "../../assets/images/tran_van_thuan.svg";
import DoHungKien from "../../assets/images/do_hung_kien.svg";
import TranVanDung from "../../assets/images/tran_van_dung.png";
import VoThuThao from "../../assets/images/vo_thu_thao.png";
import HoangThuTrang from "../../assets/images/hoang_thu_trang.svg";
import useTranslation from '../../translation/components/useTranslation';
import InfoItem from './InfoItem';

const orgList = [
   {
      img: TranVanThuan,
      name: 'org.tranvanthuan.name',
      company: 'org.tranvanthuan.company',
   },
   {
      img: NguyenTienQuang,
      name: 'org.nguyentienquang.name',
      company: 'org.nguyentienquang.company',
   },
   {
      img: LeVanQuang,
      name: 'org.levanquang.name',
      company: 'org.levanquang.company',
   },
   {
      img: PhanVanBinh,
      name: 'org.phanvanbinh.name',
      company: 'org.phanvanbinh.company',
   },
   {
      img: TranThiThanhHuong,
      name: 'org.tranthithanhhuong.name',
      company: 'org.tranthithanhhuong.company',
   },
   {
      img: DoHungKien,
      name: 'org.dohungkien.name',
      company: 'org.dohungkien.company',
   },
   {
      img: NguyenDucLien,
      name: 'org.nguyenduclien.name',
      company: 'org.nguyenduclien.company',
   },
   {
      img: HoangThuTrang,
      name: 'org.hoangthutrang.name',
      company: 'org.hoangthutrang.company',
   },
   {
      img: DoAnhTu,
      name: 'org.doanhtu.name',
      company: 'org.doanhtu.company',
   },
   {
      img: DoCamThanh,
      name: 'org.docamthanh.name',
      company: 'org.docamthanh.company',
   },
   {
      img: TranVanDung,
      name: 'org.tranvandung.name',
      company: 'org.tranvandung.company',
   },
   {
      img: VoThuThao,
      name: 'org.vothuthao.name',
      company: 'org.vothuthao.company',
   },
]

const OrganizingCommittee = memo(() => {
   const { translate } = useTranslation();
   return (
      <div className="common-layout" >
         <div className="title" id="owner-info">{translate('org.title')}</div>
         <Row gutter={[30, 40]} className="info-container">
            {
               orgList.map((item, idx) => {
                  return (
                     <Col className="gutter-row" span={8} lg={8} md={12} sm={24} xs={24} key={idx}>
                        <InfoItem data={item} />
                     </Col>
                  )
               })
            }
         </Row>
      </div>
   );
});

export default OrganizingCommittee;