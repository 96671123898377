import { Col, Row } from 'antd';
import React, { memo } from 'react';
import IconCuire from '../../icons/IconCuire';
import IconStJude from '../../icons/IconStJude';
import IconWho from '../../icons/IconWho';
import { Link } from 'react-router-dom';
import useTranslation from '../../translation/components/useTranslation';
const partnerList = [
   {
      icon: IconWho,
      label: 'partner.who.label',
      link: '/partner/who',
   },
   {
      icon: IconCuire,
      label: 'partner.cuire.label',
      link: '/partner/curie-institute',
   },
   {
      icon: IconStJude,
      label: 'partner.stjude.label',
      link: '/partner/st-jude',
   },
]

const PartnerList = memo(() => {
   const { translate } = useTranslation();
   return (
      <div className="common-layout">
         <div className="title" id="contact">{translate('partner.title')}</div>
         <Row gutter={[30, 30]} className="info-container">
            {
               partnerList.map((item, idx) => {
                  const Icon = item.icon;
                  return (
                     <Col className="gutter-row" span={8} lg={8} md={12} sm={12} xs={24} key={idx}>
                        <Link to={item.link} className="partner-link">
                           <div className="partner-container">
                              <div className="icon-partner"><Icon /></div>
                              <div className="partner-name">{translate(item.label)}</div>
                           </div>
                        </Link>
                     </Col>
                  )
               })
            }
         </Row>
      </div>
   );
});

export default PartnerList;