import { API_CONST } from "../API";
import ResponseModel from "./ResponseModel";

const getResponse = (responseBody, error) => {
   let response = new ResponseModel();
   if (responseBody) {
      response.isSuccess = responseBody.error_code === 0;
      response.errorCode = responseBody.error_code || 0;
      response.message = responseBody.message || '';
      response.data = responseBody.data || null;
      response.currentTime = responseBody.current_time || null;
   }
   if (error) {
      response.message = String(error);
   }
   return response;
}

const register = async (data) => {
   let restObj = {
      method: 'POST',
      body: data,
   };
   let url = API_CONST.REGISTER;
   try {
      let response = await fetch(url, restObj);
      let body = await response.json();
      return getResponse(body);
   } catch (error) {
      return getResponse(error);
   }
}
const getSessonList = async (data) => {
   let restObj = {
      method: 'POST',
      body: data,
   };
   let url = API_CONST.SESSON_LIST;
   try {
      let response = await fetch(url, restObj);
      let body = await response.json();
      return getResponse(body);
   } catch (error) {
      return getResponse(error);
   }
}

export const services = {
   register,
   getSessonList,
}