import React, { memo, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { services } from './services/Services';
import Localization from './translation/components/Localization';
import DefaultLayout from './view/DefaultLayout';
import Footer from './view/Footer';
import Header from './view/Header';
import PartnerDetails from './view/partner/PartnerDetails';

const App = memo(() => {
  return (
    <Localization>
      <BrowserRouter>
        <Switch>
          <Route exact={true} path="/partner/:code" component={PartnerDetails} />
          <Route path="/" component={DefaultLayout} />
        </Switch>
      </BrowserRouter>
      <Footer />
    </Localization>
  );
});

export default App;