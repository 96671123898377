import moment from "moment-timezone";

const getTimeZone = () => {
   return moment.tz.guess();
}

export const convertToDateTime = (ISOString, dateTimeFormat) => {
   const stillUtc = moment(ISOString).utc().toDate();
   return moment(stillUtc).tz(getTimeZone()).format(dateTimeFormat || 'DD/MM/YYYY - HH:mm');
}
export const convertToDate = (ISOString, dateFormat) => {
   const stillUtc = moment(ISOString).utc().toDate();
   return moment(stillUtc).tz(getTimeZone()).format(dateFormat || 'DD/MM/YYYY');
}
export const convertToTime = (ISOString, timeFormat) => {
   const stillUtc = moment(ISOString).utc().toDate();
   return moment(stillUtc).tz(getTimeZone()).format(timeFormat || 'HH: mm');
}
export const deadline = new Date('2021-12-01');

export const repeatApiTime = 600000; // 10p*60s*1000ms
// export const repeatApiTime = 120000; // test
// prepare data
export const seminor1 = [
   {
      time: '8:00 - 8:30',
      label: 'Đón tiếp',
      description: 'Chủ tọa',
   },
   {
      time: '8:30 - 9:00',
      label: 'Khai mạc hội thảo',
      description: 'Chủ tọa',
   },
   {
      time: '9:00 - 9:25',
      label: 'Điều trị ung thư Nhi trên thế giới',
      description: 'Dr Carlos Rodriguez-Galindo, St Jude Children’s Research Hospital, USA・Dr Catherine G.Lam, St Jude Children’s Research Hospital, USA',
   },
   {
      time: '9:25 - 09:50',
      label: 'Thực trạng ung thư Nhi ở Việt Nam và hướng phát triển',
      description: 'Đang cập nhật',
   },
   {
      time: '9:50 - 10:15',
      label: 'Vai trò điều trị tế bào gốc ung thư Nhi',
      description: 'GS.TS Michelle L. Hermiston, UCSF, San Francisco・TS.BS Julia Palma, Bệnh viện Luis Calvo Mackenna, Chile',
   },
   {
      time: '10:15 - 10:20',
      label: 'Nghỉ giải lao',
      description: '☕️ ☕️ ☕️',
   },
];

export const seminor2 = [
   {
      time: '10:20 - 10:45',
      label: 'Vai trò sinh học phân tử trong chẩn đoán và phân loại u nguyên bào thần kinh',
      description: 'TS. BS Vũ Đình Quang- Bệnh viện Nhi TW',
   },
   {
      time: '10:45 - 11:10',
      label: 'Kháng hoá trị trong điều trị ung thư Nhi',
      description: 'GS.TS Yi Heong Gyu- Giám đốc trung tâm ung bướu và xạ trị- Bệnh viện đa khoa quốc tế Vinmec',
   },
   {
      time: '11:10 - 11:35',
      label: 'Ảnh hưởng của tính đa hình trong kiểu gen trong biểu hiện độc tính hoá trị trong điều trị bạch cầu cấp dòng lympho',
      description: 'ThS.BSNT Phan Trúc– Bệnh viện đa khoa quốc tế Vinmec',
   },
   {
      time: '11:35 - 11:45',
      label: 'Thảo luận',
      description: 'Tất cả',
   },
];

export const seminor3 = [
   {
      time: '13:30 - 14:00',
      label: 'Chẩn đoán sớm các bệnh u đặc',
      description: 'Đang cập nhật・Catherine Lam Lam, Catherine',
   },
   {
      time: '14:00 - 14:25',
      label: 'Ảnh hưởng của Covid 19 đến điều trị ung thư Nhi',
      description: 'Đang cập nhật・TS.BS Chetan Dhamne (Bệnh viện Tata Memorial, Ấn Độ)',
   },
   {
      time: '14:25 - 14:30',
      label: 'Nghỉ giải lao',
      description: '☕️ ☕ ️☕️',
   },
   {
      time: '14:30 - 15:10',
      label: 'Phẫu thuật nội soi u ổ bụng ở trẻ em',
      description: 'GS.BS Pascale Philippe-Chomette (Bệnh viện Robert Debré, Paris)',
   },
   {
      time: '15:10 - 15:45',
      label: 'Cập nhật chẩn đoán và điều trị u tế bào mầm ở trẻ em',
      description: 'BSCKII An Thanh Dao – Đại học Y dược TP HCM',
   },
   {
      time: '15:45 - 16:30',
      label: 'Cập nhật chẩn đoán (lâm sàng, mô bệnh học) và điều trị u nguyên bào thận: Kinh ngiệm từ hội ung thư Nhi Châu Âu',
      description: 'GS.BS Kathy Prichard-Jones, Chủ tich Hiệp hội ung thư nhi quốc tế - SIOP',
   },
   {
      time: '16:30 - 17:00',
      label: 'Thảo luận',
      description: 'All guests',
   },
];

export const seminor4 = [
   {
      time: '13:30 - 14:00',
      label: 'Cập nhật phân loại mô bệnh học u hệ thống TKTW',
      description: 'PGS.TS Tạ Văn Tờ (Giám đốc Trung tâm Giải phẫu bệnh và sinh học phân tử, BV K)',
   },
   {
      time: '14:00 - 14:25',
      label: 'Xạ trị u hệ thống TKTW trẻ em',
      description: 'GS. TS Claire Alapetite (Chuyên gia xạ trị- Bệnh viện Curie)',
   },
   {
      time: '14:25 - 14:30',
      label: 'Nghỉ giải lao',
      description: '☕️ ☕ ️☕️',
   },
   {
      time: '14:30 - 15:10',
      label: 'Vai trò của cộng hưởng từ trong chẩn đoán và đánh giá mức độ đáp ứng sau hoá trị so sánh với đánh giá trên giải phẫu bệnh sau mổ',
      description: 'GS.TS Hervé Brisse (Viện Curie, Pháp)',
   },
   {
      time: '15:10 - 15:45',
      label: 'Cập nhật mô bệnh học trong chẩn đoán và đánh giá đáp ứng sau hoá trị tiền phẫu u xương ác tính',
      description: 'GS.TS Michelle L. Hermiston, UCSF, San Francisco・TS.BS Julia Palma, Bệnh viện Luis Calvo Mackenna, Chile',
   },
   {
      time: '15:45 - 16:30',
      label: 'Phẫu thuật tạo hình chi trong phẫu thuật u xương ác tính',
      description: 'GS.TS Pierre Mary (Bệnh viện APHP, Pháp)',
   },
];

export const seminor5 = [
   {
      time: '9:00 - 9:30',
      label: 'U nguyên bào lympho cấp tính ở thanh thiếu niên và người trẻ tuổi',
      description: 'GS.BS Selina. Luger (Bệnh viện Đại học Pennsylvania, Hoa Kỳ)',
   },
   {
      time: '9:30 - 09:50',
      label: 'Điều trị đích u lympho không Hodgkin ác tính tế bào B và bạch cầu cấp dòng L3',
      description: "Đang cập nhật GS.BS Hiroto Inaba, Bệnh viện St Jude Children’s Research, Hoa Kỳ",
   },
   {
      time: '9:50 - 10:15',
      label: 'Vai trò của dòng chảy tế bào trong chẩn đoán, phân loại và đánh giá đáp ứng bạch cầu cấp dòng lympho B',
      description: 'TS Trần Thị Hồng Hà (Nguyên trưởng khoa huyết học- Bệnh viện Nhi TW)',
   },
   {
      time: '10:15 - 10:45',
      label: 'Cập nhật chẩn đoán điều trị và điều trị u lympho Hodgkin trẻ em',
      description: 'Đang cập nhật・TS.BS Monika Metzger (Bệnh viện St Jude Children’s Reseach, Hoa Kỳ)・TS.BS Scott Howard (UTHSC, Hoa Kỳ)',
   },
   {
      time: '10:45 - 11:10',
      label: 'Điều trị bạch cầu cấp tái phát',
      description: 'Đang cập nhật・GS.TS Allen Yeoh (Bệnh viện đại học Quốc gia Singapore)',
   },
   {
      time: '11:10 - 11:30',
      label: 'Thảo luận',
      description: 'All guests',
   },
];