import React, { memo, useState } from 'react';
import IconMenu from '../icons/IconMenu';
import IconClose from '../icons/IconClose';
import IconInprocess from '../icons/IconInprocess';
import Logo from '../assets/images/logo.png';
import LanguageDropdown from '../utils/LanguageDropdown';
import useTranslation from '../translation/components/useTranslation';
import { Dropdown, Menu } from 'antd';

const Header = memo(({ data }) => {
   const pathname = window.location.pathname;
   const { translate } = useTranslation();
   const [isOpenMenu, setIsOpentmenu] = useState(false);
   const handleClickItem = (id) => {
      if (pathname && pathname.includes('partner')) {
         window.location.href = `/#${id}`;
      } else {
         let element = document.getElementById(id);
         if (element) {
            element.scrollIntoView({ behavior: "smooth" });
         }
      }

   };

   const handleOpenMenuMB = () => {
      setIsOpentmenu(!isOpenMenu);
   };

   const handleClickItemMB = (id) => {
      setIsOpentmenu(!isOpenMenu);
      if (pathname && pathname.includes('partner')) {
         window.location.href = `/#${id}`;
      } else {
         let element = document.getElementById(id);
         if (element) {
            element.scrollIntoView({ behavior: "smooth" });
         }
      }
   };

   const renderStatusHeader = () => {
      let statusCode = data?.status;
      if (statusCode === 'NOT_STARTED') {
         return <span className="header-button" onClick={() => handleClickItem('register')}>{translate('register.title')}</span>;
      } else if (statusCode === 'IN_PROGRESS') {
         const listSessions = data?.active_sessions;
         if (listSessions && listSessions.length > 1) {
            const menu = (
               <Menu className="menu-sessions">
                  {
                     listSessions.map((session, idx) => {
                        return (
                           <Menu.Item key={idx}>
                              <a href={session?.joining_url} target="_blank" rel="noreferrer"><span>{session?.title}. {session?.name}</span></a>
                           </Menu.Item>
                        )
                     })
                  }
               </Menu>
            );
            return (
               <Dropdown overlay={menu} trigger={['click']}>
                  <div className="header-button inprocess"><IconInprocess />{translate('status.in_process')}</div>
               </Dropdown>
            )
         } else if (listSessions && listSessions.length === 1) {
            return <a href={listSessions[0]?.joining_url} target="_blank" rel="noreferrer"><div className="header-button inprocess"><IconInprocess />{translate('status.in_process')}</div></a>;
         } else {
            return <div className="header-button inprocess"><IconInprocess />{translate('status.in_process')}</div>;
         }
      } else if (statusCode === 'COMPLETED') {
         return <a href={'https://nci.elitelearning.vn/'} target="_blank" rel="noreferrer"><span className="header-button finished">{translate('status.completed')}</span></a>;
      }
   }

   const renderStatusButtonMB = () => {
      let statusCode = data?.status;
      if (statusCode === 'NOT_STARTED') {
         return <div className="header-button" onClick={() => handleClickItemMB('register')}>{translate('register.title')}</div>;
      } else if (statusCode === 'IN_PROGRESS') {
         const listSessions = data?.active_sessions;
         if (listSessions && listSessions.length > 1) {
            const menu = (
               <Menu className="menu-sessions">
                  {
                     listSessions.map((session, idx) => {
                        return (
                           <Menu.Item key={idx}>
                              <a href={session.joining_url} target="_blank" rel="noreferrer"><span>{session.title}. {session.name}</span></a>
                           </Menu.Item>
                        )
                     })
                  }
               </Menu>
            );
            return (
               <Dropdown overlay={menu} trigger={['click']}>
                  <div className="header-button inprocess"><IconInprocess />{translate('status.in_process')}</div>
               </Dropdown>
            )
         } else if (listSessions && listSessions.length === 1) {
            return <a href={listSessions[0]?.joining_url} target="_blank" rel="noreferrer"><div className="header-button inprocess"><IconInprocess />{translate('status.in_process')}</div></a>;
         } else {
            return <div className="header-button inprocess"><IconInprocess />{translate('status.in_process')}</div>;
         }
      } else if (statusCode === 'COMPLETED') {
         return <a href={'https://nci.elitelearning.vn/'} target="_blank" rel="noreferrer"><div className="header-button finished">{translate('status.completed')}</div></a>;
      }
   }

   return (
      <div className="header-container">
         <div className="common-layout">
            <div className="header-content">
               <a href='/'><img src={Logo} className="logo-web" alt="logo" /></a>
               <div className="header-left">
                  {/* <span className="header-link" onClick={() => handleClickItem('introduction')} >GIỚI THIỆU</span> */}
                  <span className="header-link" onClick={() => handleClickItem('seminor-program')}>{translate('header.program')}</span>
                  <span className="header-link" onClick={() => handleClickItem('owner-info')}>{translate('header.info')}</span>
                  {/* <span className="header-link" onClick={() => handleClickItem('speaker')}>DIỄN GIẢ</span> */}
                  {/* <span className="header-link" onClick={() => handleClickItem('contact')}>LIÊN HỆ</span> */}
                  <LanguageDropdown />
                  {renderStatusHeader()}
               </div>
               <div className="menu-icon" onClick={handleOpenMenuMB}>
                  {
                     isOpenMenu ?
                        <IconClose />
                        :
                        <IconMenu />
                  }

               </div>
            </div>
         </div>
         {
            isOpenMenu &&
            <div className="menu-mb">
               {/* <div className="header-link" onClick={() => handleClickItemMB('introduction')} >GIỚI THIỆU</div> */}
               <div className="header-link" onClick={() => handleClickItemMB('seminor-program')}>{translate('header.program')}</div>
               <div className="header-link" onClick={() => handleClickItemMB('owner-info')}>{translate('header.info')}</div>
               {/* <div className="header-link" onClick={() => handleClickItemMB('speaker')}>DIỄN GIẢ</div> */}
               {/* <div className="header-link" onClick={() => handleClickItemMB('contact')}>LIÊN HỆ</div> */}
               <div className="header-link"><LanguageDropdown onChange={handleOpenMenuMB} /></div>
               {renderStatusButtonMB()}
            </div>
         }
      </div>
   );
});

export default Header;