import React from 'react';

const IconMenu = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M16 4H0V3h16v1zM16 13H0v-1h16v1z"
         fill="#000"
      />
   </svg>
);

export default IconMenu;